import {useEffect, useState} from 'react';
import {
  Cell,
  Column,
  Flex,
  Heading,
  ProgressCircle,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text
} from '@adobe/react-spectrum';
import Disclaimer from '../components/Disclaimer';
import InfoOutline from '@spectrum-icons/workflow/InfoOutline';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ToastQueue} from '@react-spectrum/toast';
import Pagination from '../components/Pagination';

const columns = [
  {
    name: 'Pipeline',
    uid: 'pipelineName'
  },
  {
    name: 'Environment',
    uid: 'environmentName'
  },
  {
    name: 'Changed',
    uid: 'changed'
  },
  {
    name: 'To review',
    uid: 'review'
  },
  {
    name: 'Flagged',
    uid: 'flagged'
  },
  {
    name: 'Status',
    uid: 'status'
  },
  {
    name: 'Created',
    uid: 'createdAt'
  }
];

async function getReports(page, signal) {
  const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports?page=${page - 1}&size=20`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('apiKey')}`
    },
    signal
  });

  const json = await res.json();

  if (!res.ok) {
    ToastQueue.negative(json.message, {timeout: 5000});
  }

  return json;
}

export default function Reports() {
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();
  const page = Number(queryParams.get('page')) || 1;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  if (page < 1 || page > totalPages) {
    navigate({search: `?page=${1}`});
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getReports(page, signal).then((json) => {
      setItems(json.content);
      setLoading(false);
      setTotalPages(Math.ceil(json._totalNumberOfItems / 20));
    });

    return () => controller.abort();
  }, [page]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const intervalId = setInterval(async () => {
      const json = await getReports(page, signal);
      const allItemsHaveStatusDifferentThanInProgress = json.content.every((item) => item.status !== 'IN_PROGRESS');

      if (allItemsHaveStatusDifferentThanInProgress) {
        clearInterval(intervalId);
      }

      setItems(json.content);
      setTotalPages(Math.ceil(json._totalNumberOfItems / 20));
    }, 5000);

    return () => {
      clearInterval(intervalId);
      controller.abort();
    };
  }, [page]);

  function navigateToPage(p) {
    if (p >= 1 && p <= totalPages) {
      setQueryParams(p);
      navigate({search: `?page=${p}`});
    }
  }

  return (
    <>
      <Disclaimer />
      <TableView
        onAction={(id) => navigate(`/view-report/${id}`)}
        density="spacious"
        width="100%"
        aria-label="Reports Table"
      >
        <TableHeader columns={columns}>
          {(column) => (
            <Column
              key={column.uid}
              align={
                column.uid === 'changed' || column.uid === 'review' || column.uid === 'flagged' ? 'center' : 'start'
              }
            >
              <Text UNSAFE_className="reports-table-header">{column.name}</Text>
            </Column>
          )}
        </TableHeader>
        <TableBody items={items} loadingState={loading ?? 'loading'}>
          {(item) => <Row>{(columnKey) => renderCell(item, columnKey)}</Row>}
        </TableBody>
      </TableView>
      <Pagination navigateToPage={navigateToPage} pageNumber={page} totalPages={totalPages} />
    </>
  );
}

function parseAndFormatDate(dateString) {
  const date = new Date(dateString);

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  return date.toLocaleString('en-US', options).replace(/,([^,]*)$/, ' •$1');
}

function renderCell(item, columnKey) {
  switch (columnKey) {
    case 'status':
      return (
        <Cell>
          <Flex alignItems="center" gap="size-100">
            {item[columnKey] === 'Running' ? <ProgressCircle isIndeterminate size="S" /> : <InfoOutline size="S" />}
            <Text>{item[columnKey]}</Text>
          </Flex>
        </Cell>
      );
    case 'changed':
      return (
        <Cell>
          <Flex justifyContent="center">
            <Text>
              {item.stats.diverged} / {item.stats.total}
            </Text>
          </Flex>
        </Cell>
      );
    case 'review':
      return (
        <Cell>
          <Flex justifyContent="center">
            <Text
              UNSAFE_className={item.stats.diverged - item.stats.flagged - item.stats.approved <= 0 ? 'faded-text' : ''}
            >
              {Math.max(item.stats.diverged - item.stats.flagged - item.stats.approved, 0)}
            </Text>
          </Flex>
        </Cell>
      );
    case 'flagged':
      return (
        <Cell>
          <Flex justifyContent="center">
            <Text UNSAFE_className={item.stats.flagged <= 0 ? 'faded-text' : ''}>{item.stats.flagged}</Text>
          </Flex>
        </Cell>
      );
    case 'createdAt':
      return (
        <Cell>
          <Text>{parseAndFormatDate(item[columnKey])}</Text>
        </Cell>
      );
    default:
      return (
        <Cell>
          <Flex direction="column">
            <Text>{item.metadata[columnKey]}</Text>
            <Heading level={5} UNSAFE_style={{color: '#b1b1b1'}}>
              {columnKey === 'environmentName'
                ? item.metadata.environmentType?.toUpperCase()
                : item.metadata.pipelineType?.toUpperCase()}
            </Heading>
          </Flex>
        </Cell>
      );
  }
}
