import React from 'react';
import {ToastQueue} from '@react-spectrum/toast';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    console.log('getDerivedStateFromError', error);
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    console.log('componentDidCatch', error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      ToastQueue.negative('Something went wrong.', {timeout: 5000});
    }

    return this.props.children;
  }
}
