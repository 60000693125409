import {lazy, Suspense, useEffect} from 'react';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {Flex, ProgressCircle} from '@adobe/react-spectrum';
import {useMfeContext} from './providers/Context';
import ErrorBoundary from './components/ErrorBoundary';
import Reports from './pages/Reports';

const Compare = lazy(() => import('./pages/Compare'));
const ViewReport = lazy(() => import('./pages/ViewReport'));
const EditPipeline = lazy(() => import('./pages/EditPipeline'));

export default function App() {
  const context = useMfeContext();
  const navigate = useNavigate();

  //the app is meant to be running in an iframe, redirect to experience.adobe.com if someone opens it directly
  if (!context || window.self === window.top) {
    window.location.href = process.env.REACT_APP_REDIRECT_LOCATION_NO_IFRAME;
  }

  useEffect(() => {
    if (context && context.searchParams) {
      const {reportId, pageId} = context.searchParams;
      if (reportId && pageId) {
        navigate(`/compare/${reportId}/${pageId}`);
      } else if (reportId) {
        navigate(`/view-report/${reportId}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex direction="column" alignItems="center" gap="size-75" height="100%">
      <Suspense fallback={<ProgressCircle aria-label="Loading…" isIndeterminate size="L" />}>
        <ErrorBoundary>
          <Routes>
            <Route index element={<Reports />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/view-report/:id" element={<ViewReport />} />
            <Route path="/compare/:id/:pageId" element={<Compare />} />
            <Route path="/edit-pipeline" element={<EditPipeline />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </Flex>
  );
}
