import React, {createContext, useContext, useEffect} from 'react';
import {withPropsReceiver} from '@assets/microfrontend';

const MfeContext = createContext(null);

function Context(props) {
  useEffect(() => {
    if (props.getAccessToken) {
      props.getAccessToken().then((token) => sessionStorage.setItem('apiKey', token));
    }
  }, [props]);

  return (
    <MfeContext.Provider
      value={{
        handleReportSelect: props.handleReportSelect,
        handlePageSelect: props.handlePageSelect,
        handleBack: props.handleBack,
        searchParams: props.searchParams,
        programId: props.programId,
        getAccessToken: props.getAccessToken
      }}
    >
      {props.children}
    </MfeContext.Provider>
  );
}

export const useMfeContext = () => useContext(MfeContext);
export default withPropsReceiver(Context, '@fred', () => null, true);
