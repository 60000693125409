import React from 'react';
import {defaultTheme, Provider} from '@adobe/react-spectrum';

export default function Theme({children}) {
  return (
    <Provider
      theme={defaultTheme}
      colorScheme="light"
      UNSAFE_style={{
        height: '100%',
        backgroundColor: 'inherit'
      }}
    >
      {children}
    </Provider>
  );
}
