import {ActionButton, Flex, Text} from '@adobe/react-spectrum';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import ChevronLeft from '@spectrum-icons/workflow/ChevronLeft';

export default function Pagination({navigateToPage, pageNumber, totalPages}) {
  return (
    <Flex alignItems="center" gap="size-50">
      <ActionButton isQuiet onPress={() => navigateToPage(pageNumber - 1)} isDisabled={pageNumber === 1}>
        <ChevronLeft />
      </ActionButton>
      <Text UNSAFE_className="faded-text">
        {pageNumber} of {totalPages} pages
      </Text>
      <ActionButton isQuiet onPress={() => navigateToPage(pageNumber + 1)} isDisabled={pageNumber === totalPages}>
        <ChevronRight />
      </ActionButton>
    </Flex>
  );
}
