import {Button, ButtonGroup, Flex, Heading, Text, View} from '@adobe/react-spectrum';

export default function Disclaimer() {
  const isDismissed = localStorage.getItem('disclaimer-dismissed');
  if (isDismissed) {
    return null;
  }
  return (
    <View padding="size-200" borderWidth="thin" borderColor="gray-300" borderRadius="medium">
      <Flex alignItems="center" gap="size-500">
        <View flex={4}>
          <Heading>Welcome to Visual Regression reports!</Heading>
          <Text>
            Visual Regression is a new check that can be enabled on your production pipeline. Is compares screenshots of
            your website before and after deployment of your code to give you the confidence in your changes. You can
            always come here to see the history of the reports and configure pages for comparison.
          </Text>
        </View>
        <ButtonGroup>
          {/*<Button variant="secondary">Learn More</Button>*/}
          <Button variant="secondary" onPress={() => localStorage.setItem('disclaimer-dismissed', 'true')}>
            Close
          </Button>
        </ButtonGroup>
      </Flex>
    </View>
  );
}
